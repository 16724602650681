<!-- 新建环境、编辑环境共用此页面 -->
<template>
	<div class="AddEnviroment">
		<main>
			<nav>
				<a-button icon="left" type="primary" @click="$router.push('/manage/environment')">返回</a-button>
				<h1>{{ $route.query.id ? "编辑环境" : "新建环境" }}</h1>
			</nav>
			<div class="form">
				<h2>环境基本信息</h2>
				<template>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="required label"> 环境名称： </a-col>
								<a-col :span="19">
									<a-input v-model="environment.env_name" placeholder="请输入环境名称" />
								</a-col>
							</a-row>
						</a-col>
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="required label"> 所属平台： </a-col>
								<a-col :span="19">
									<input
										class="ant-input"
										readonly="true"
										placeholder="请选择所属平台"
										:value="platform_title"
										@focus="EditPlatform_visible = true" />
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 环境标签： </a-col>
								<a-col :span="19">
									<a-select v-model="environment.tagIds" mode="multiple" style="width: 380px" placeholder="请选择环境标签">
										<a-select-option :value="item.id" v-for="item in tagList" :key="item.id">
											{{ item.tag }}
										</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 授权成员： </a-col>
								<a-col :span="19">
									<a-select v-model="environment.member" mode="multiple" style="width: 380px" placeholder="请选择授权成员">
										<a-select-option :value="item.id" v-for="item in memberList" :key="item.id">
											{{ item.username }}
										</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 企业简称： </a-col>
								<a-col :span="19">
									<a-input v-model="environment.business_short" placeholder="请输入企业简称" />
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</template>
				<h2>账号信息</h2>
				<template>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 店铺账号： </a-col>
								<a-col :span="19">
									<a-input v-model="environment.shop_account" placeholder="请输入店铺账号" />
								</a-col>
							</a-row>
						</a-col>
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 店铺密码： </a-col>
								<a-col :span="19">
									<a-input-password
										v-model="environment.shop_password"
										placeholder="请输入店铺密码"
										type="password"
										autocomplete="new-password" />
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 账号备注： </a-col>
								<a-col :span="19">
									<a-input v-model="config.beizhu" placeholder="请输入账号备注" type="textarea" />
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</template>
				<h2>环境参数</h2>
				<template>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 浏览器内核： </a-col>
								<a-col :span="19">
									<div class="tags">
										<div class="item active">
											<img width="22" height="22" src="@/assets/images/add_env/yt.png" alt="" />
											<span>Chrome</span>
										</div>
									</div>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> 系统平台： </a-col>
								<a-col :span="19">
									<div class="tags">
										<div
											class="item"
											v-for="(item, index) in deviceSystemConfig.platform"
											:key="index"
											:class="{ active: config.platform === item.platform }"
											@click="changePlatform(item.platform)">
											<img :src="require(`@/assets/images/add_env/${item.platform}.png`)" alt="" />
											<span>{{ item.platform }}</span>
										</div>
									</div>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row type="flex">
								<a-col :span="5" class="label"> 系统版本： </a-col>
								<a-col :span="19">
									<a-select style="width: 380px" placeholder="请选择系统版本" v-model="config.os_version" @change="osVersionChange">
										<a-select-option value="">随机</a-select-option>
										<a-select-option v-for="(item, index) in os_version_options" :key="index" :value="item">{{ item }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row type="flex">
								<a-col :span="5" class="label"> 浏览器版本： </a-col>
								<a-col :span="19">
									<a-select placeholder="请选择浏览器版本" v-model="config.chrome_version" @change="getUserAgent">
										<a-select-option value="">随机</a-select-option>
										<a-select-option v-for="(item, index) in chrome_version_options" :key="index" :value="item">{{ item }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row>
								<a-col :span="5" class="label"> User Agent： </a-col>
								<a-col :span="19">
									<textarea
										:value="`${config.user_agent}${config.ua_json.ua_fullversion ? ` [${config.ua_json.ua_fullversion}]` : ''}`"
										type="textarea"
										class="ant-input"
										rows="4"
										readonly="true" />
								</a-col>
							</a-row>
						</a-col>
						<a-col :span="12">
							<a-button icon="redo" type="primary" @click="getUserAgent">刷一刷</a-button>
						</a-col>
					</a-row>

					<a-row type="flex">
						<a-col :span="12">
							<a-row type="flex">
								<a-col :span="5" class="label"> Cookie： </a-col>
								<a-col :span="19">
									<textarea
										v-model="config.cookie"
										type="textarea"
										class="ant-input"
										rows="5"
										placeholder='选填，仅支持JSON格式，例如：
	[
		{
			"name":"name",
			"value":"value",
			"domain":"domain",
			"path":"/",
			"expires":1724405800,
			"httpOnly":false,
			"secure":true,
			"session":true
		}
	]'
										@blur="validateCookie" />
								</a-col>
							</a-row>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row type="flex">
								<a-col :span="5" class="label">打开指定网站：</a-col>
								<a-col :span="19">
									<a-input v-model="custom_url_input" placeholder="请输入 URL" />
								</a-col>
							</a-row>
							<a-row type="flex">
								<a-col :span="5" class="label"></a-col>
								<a-col :span="19">
									<div class="urls" v-if="config.custom_url.length">
										{{ config.custom_url.join(", ") }}
										<span @click="customUrlDialogVisible = true">编辑</span>
									</div>
								</a-col>
							</a-row>
						</a-col>
						<a-col :span="12">
							<a-button icon="plus" type="primary" @click="addUrl">添加</a-button>
							<a-button icon="delete" type="danger" @click="clearCustomUrl" :disabled="config.custom_url.length === 0">清空</a-button>
						</a-col>
					</a-row>
					<a-row type="flex">
						<a-col :span="12">
							<a-row type="flex">
								<a-col :span="5" class="label code_2fa"> 2步验证密钥(2FA)： </a-col>
								<a-col :span="19">
									<a-input v-model="config.key2fa" placeholder="请输入由数字、英文组合的2FA密钥" :maxLength="64" />
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</template>
				<h2>设备绑定</h2>
				<template>
					<a-row type="flex">
						<a-col :span="12">
							<a-row type="flex">
								<a-col :span="5" class="label">设备选择：</a-col>
								<a-col :span="19">
									<a-row>
										<a-col>
											<a-radio-group v-model="config.device_type" @change="changeDeviceType">
												<a-radio :value="1">平台设备</a-radio>
												<a-radio :value="2">自有设备（自定义代理）</a-radio>
												<!-- <a-radio :value="3">本地直连</a-radio> -->
											</a-radio-group>
										</a-col>
									</a-row>
									<a-row>
										<a-select
											:disabled="config.device_type == 3"
											placeholder="请选择设备"
											v-model="environment.device_id"
											style="margin-top: 10px">
											<a-select-option
												v-for="(item, index) in deviceList.filter(d => d.type == config.device_type)"
												:key="index"
												:value="item.id">
												{{ item.device_name }}
											</a-select-option>
										</a-select>
									</a-row>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</template>
				<h3 :class="{ active: advancedSettingVisible }" @click="openAdvancedSetting" ref="advancedSetting">
					<span>高级设置</span>
					<img src="@/assets/img/xinjian/xiaa.png" alt="" />
				</h3>
				<div class="advancedSetting" v-show="advancedSettingVisible">
					<h2>常用设置</h2>
					<template>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 禁止加载图片： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_blockpic" />
									<span>开启后，浏览器将不加载图片，提升速度，节省流量</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 禁止自动播放： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_disablevideo" />
									<span>开启后，禁止视频自动播放，预加载。（部分网站可能无效）</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 禁止播放声音： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_blocksound" />
									<span>开启后，正常播放视频、音频但是静音</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 禁止网页通知： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.nofity_status" />
									<span>禁止网页弹出通知弹窗</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 同步标签页： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_recordtaburl" />
									<span>同步浏览器已打开的标签页面</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 同步书签： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_sync_bookmarks" />
									<span>同步浏览器书签</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 同步IndexedDB： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_sync_indexedDB" />
									<span>同步IndexedDB</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 同步LocalStorage： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_sync_localstorage" />
									<span>同步LocalStorage</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 启动前清除缓存： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_deltemp_blocksync" />
									<span>启动浏览器前删除本地缓存，且不同步后台已有的缓存</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 启动前删除Cookie： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_delcookie_blocksync" />
									<span>启动浏览器前删除本地Cookie，且不同步后台已有的Cookie</span>
								</label>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label"> 禁用硬件加速： </a-col>
							<a-col flex="auto">
								<label>
									<a-switch v-model="config.is_disable_gpu" />
									<span>禁止浏览器使用硬件加速功能</span>
								</label>
							</a-col>
						</a-row>
					</template>
					<h2>指纹设置</h2>
					<div class="fingerprintSettings">
						<a-row type="flex">
							<a-col flex="130px" class="label"> 时区： </a-col>
							<a-col flex="380px">
								<a-row>
									<a-col>
										<label>
											<a-switch :checked="!config.is_custom_timezone" @change="customTimezoneChange" />
											<span>{{ config.is_custom_timezone ? "自定义时区" : "基于IP生成对应的时区" }}</span>
										</label>
									</a-col>
									<a-col v-if="config.is_custom_timezone" style="margin-top: 10px">
										<a-select v-model="config.timezone" placeholder="请选择自定义时区" :class="{ selected: config.timezone }">
											<a-select-option :value="item.realValue" v-for="item in timezoneList" :key="item.realValue">
												{{ item.showValue }}
											</a-select-option>
										</a-select>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">WebRTC：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.webrtc_set" button-style="solid">
									<a-radio-button :value="0"> 禁用 </a-radio-button>
									<a-radio-button :value="1"> 真实 </a-radio-button>
									<a-radio-button :value="2"> 替换 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">地理位置：</a-col>
							<a-col flex="auto">
								<a-row>
									<a-col>
										<a-radio-group v-model="config.location.location_status" button-style="solid">
											<a-radio-button :value="0"> 询问 </a-radio-button>
											<a-radio-button :value="1"> 允许 </a-radio-button>
											<a-radio-button :value="2"> 禁止 </a-radio-button>
										</a-radio-group>
									</a-col>
									<a-col v-if="config.location.location_status != 2" style="margin-top: 10px">
										<label>
											<a-switch v-model="config.location.is_baseon_location" />
											<span>{{ config.location.is_baseon_location ? "基于IP生成对应的地理位置" : "自定义地理位置" }}</span>
										</label>
									</a-col>
									<a-col v-if="config.location.location_status != 2 && !config.location.is_baseon_location">
										<a-row type="flex" style="margin: 10px 0">
											<a-col flex="80px">经度：</a-col>
											<a-col>
												<a-input-number v-model="config.location.longitude" :min="-180" :max="180" />
											</a-col>
										</a-row>
										<a-row type="flex" style="margin: 10px 0">
											<a-col flex="80px">纬度：</a-col>
											<a-col>
												<a-input-number v-model="config.location.latitude" :min="-90" :max="90" />
											</a-col>
										</a-row>
										<a-row type="flex" style="margin: 10px 0">
											<a-col flex="80px">精度(米)：</a-col>
											<a-col>
												<a-input-number v-model="config.location.precision" :min="10" :max="5000" />
											</a-col>
										</a-row>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">语言：</a-col>
							<a-col flex="380px">
								<a-row>
									<a-col>
										<label>
											<a-switch v-model="config.language" />
											<span>{{ config.language ? "基于IP生成对应的国家语言" : "自定义语言" }}</span>
										</label>
									</a-col>
									<a-col v-if="!config.language">
										<Draggable
											:list="config.langSortList"
											group="lang"
											class="langSortList"
											:forceFallback="true"
											v-bind="{ animation: 200, group: 'description', disabled: false, ghostClass: 'ghost' }">
											<div class="language-item" v-for="(item, index) in config.langSortList" :key="index">
												<span>{{ languageList.find(l => l.value == item).name }}</span>
												<img src="@/assets/img/rpa/create/icon_sort.svg" alt="" />
											</div>
										</Draggable>
										<a-button type="primary" size="small" style="margin: 10px 0 0" @click="openAddLanguageDialog">添加语言</a-button>
										<a-modal v-model="addLanguageVisible" title="添加语言" @ok="addLanguageConfirm">
											<a-alert message="最多添加10种语言" banner />
											<a-select
												v-model="selectedLanguage"
												v-if="addLanguageVisible"
												mode="multiple"
												style="width: 100%; margin-top: 10px"
												@change="languageChange">
												<a-select-option v-for="item in languageList" :key="item.value" :value="item.value">
													{{ item.name }}
												</a-select-option>
											</a-select>
										</a-modal>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">分辨率：</a-col>
							<a-col flex="auto">
								<a-row>
									<a-col>
										<a-radio-group v-model="config.isresolution" button-style="solid">
											<a-radio-button value="1"> 随机匹配 </a-radio-button>
											<a-radio-button value="2"> 自定义 </a-radio-button>
										</a-radio-group>
									</a-col>
									<a-col v-if="config.isresolution == 2">
										<div class="resolution">
											<a-input-number
												v-model="resolution_x"
												:min="500"
												:max="15360"
												:parser="value => value.replaceAll('.', '')"
												@change="resolutionInputChange" />
											<span>&nbsp;X&nbsp;</span>
											<a-input-number
												v-model="resolution_y"
												:min="100"
												:max="15360"
												:parser="value => value.replaceAll('.', '')"
												@change="resolutionInputChange" />
											<span>&nbsp;&nbsp;&nbsp;</span>
											<a-dropdown :trigger="['click']" overlayClassName="selectVariableDropDown">
												<a-input readOnly value="使用预设" placeholder="" style="width: 160px">
													<a-icon slot="suffix" style="color: #999; font-size: 10px" type="down" />
												</a-input>
												<a-menu slot="overlay">
													<a-menu-item v-for="(item, index) in resolutionList" :key="index" @click="resolutionChange(item)">{{
														item
													}}</a-menu-item>
												</a-menu>
											</a-dropdown>
										</div>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">字体指纹：</a-col>
							<a-col flex="800px">
								<a-row>
									<a-col>
										<label>
											<a-switch v-model="config.isuse_font" />
											<span>{{ config.isuse_font ? "使用指纹字体" : "不使用指纹字体" }}</span>
										</label>
									</a-col>
									<a-col>
										<div class="font_list" v-if="config.isuse_font">
											<div class="selectedFontList">
												<div class="font">
													{{ config.fontlist.toString() }}
												</div>
												<div class="count">({{ config.fontlist.length }})</div>
											</div>
											<div class="option">
												<div class="edit" @click="selectFontDialogVisible = true">编辑</div>
												<div class="reload" @click="getRandomFonts">刷一刷</div>
											</div>
										</div>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">Canvas：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.canvas" button-style="solid">
									<a-radio-button :value="true"> 噪音 </a-radio-button>
									<a-radio-button :value="false"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">WebGL图像：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.webgl_graphic" button-style="solid">
									<a-radio-button :value="1"> 噪音 </a-radio-button>
									<a-radio-button :value="0"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">WebGL元数据：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.webgl_des.status" button-style="solid">
									<a-radio-button :value="true"> 自定义 </a-radio-button>
									<a-radio-button :value="false"> 关闭 </a-radio-button>
								</a-radio-group>
								<div class="webgl_des" v-if="config.webgl_des.status">
									<a-row type="flex">
										<a-col flex="120px">WebGL厂商：</a-col>
										<a-col flex="auto">
											<a-input v-model="config.webgl_des.webgl_oem" />
										</a-col>
									</a-row>
									<a-row type="flex" style="margin-top: 10px">
										<a-col flex="120px">WebGL渲染：</a-col>
										<a-col flex="auto" style="display: flex; align-items: center">
											<a-input v-model="config.webgl_des.webgl_device" />
											<a-button icon="redo" type="primary" @click="getWebglDes">刷一刷</a-button>
										</a-col>
									</a-row>
								</div>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">AudioContext：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.audio_context" button-style="solid">
									<a-radio-button :value="true"> 噪音 </a-radio-button>
									<a-radio-button :value="false"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">媒体设备：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.media_device" button-style="solid">
									<a-radio-button :value="true"> 噪音 </a-radio-button>
									<a-radio-button :value="false"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">ClientRects：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.client_rects" button-style="solid">
									<a-radio-button :value="true"> 噪音 </a-radio-button>
									<a-radio-button :value="false"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">SpeechVoices：</a-col>
							<a-col flex="auto">
								<a-radio-group v-model="config.speech_voices" button-style="solid">
									<a-radio-button :value="true"> 噪音 </a-radio-button>
									<a-radio-button :value="false"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">硬件并发数：</a-col>
							<a-col flex="380px">
								<a-select v-model="config.hardware_concurrency">
									<a-select-option v-for="item in hardware_concurrency_options" :key="item.value" :value="item">
										{{ item }}
									</a-select-option>
								</a-select>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">设备内存：</a-col>
							<a-col flex="380px">
								<a-select v-model="config.memory_size">
									<a-select-option v-for="item in memory_size_options" :key="item.value" :value="item">
										{{ item }}
									</a-select-option>
								</a-select>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">设备名称：</a-col>
							<a-col flex="380px">
								<a-row type="flex">
									<a-col :span="24">
										<a-radio-group v-model="config.ispc_name" button-style="solid">
											<a-radio-button :value="true"> 自定义 </a-radio-button>
											<a-radio-button :value="false"> 关闭 </a-radio-button>
										</a-radio-group>
									</a-col>
									<a-col v-if="config.ispc_name" :span="24" style="display: flex; align-items: center; margin-top: 10px">
										<a-input :value="config.pc_name" readOnly />
										<a-button type="primary" icon="redo" @click="getDeviceName">刷一刷</a-button>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">MAC地址：</a-col>
							<a-col flex="380px">
								<a-row type="flex">
									<a-col :span="24">
										<a-radio-group :value="!!config.mac_address" button-style="solid" @change="getMacAddress">
											<a-radio-button :value="true"> 自定义 </a-radio-button>
											<a-radio-button :value="false"> 关闭 </a-radio-button>
										</a-radio-group>
									</a-col>
									<a-col v-if="config.mac_address" :span="24" style="display: flex; align-items: center; margin-top: 10px">
										<a-input :value="config.mac_address" readOnly />
										<a-button type="primary" icon="redo" @click="getMacAddress()">刷一刷</a-button>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">Do Not Track：</a-col>
							<a-col flex="380px">
								<a-radio-group v-model="config.do_not_track" button-style="solid">
									<a-radio-button :value="0"> 默认 </a-radio-button>
									<a-radio-button :value="1"> 开启 </a-radio-button>
									<a-radio-button :value="2"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">端口扫描保护：</a-col>
							<a-col flex="380px">
								<a-row>
									<a-col>
										<a-radio-group v-model="config.port_protect.status" button-style="solid">
											<a-radio-button :value="true"> 启用 </a-radio-button>
											<a-radio-button :value="false"> 关闭 </a-radio-button>
										</a-radio-group>
									</a-col>
									<a-col style="margin-top: 10px" v-if="config.port_protect.status">
										<a-input
											v-model="config.port_protect.list"
											@change="port_protect_list_change"
											placeholder="允许被扫描的端口，多个端口以逗号分割" />
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">蓝牙设备：</a-col>
							<a-col flex="380px">
								<a-radio-group v-model="config.is_enablebluetooth" button-style="solid">
									<a-radio-button :value="true"> 允许 </a-radio-button>
									<a-radio-button :value="false"> 禁用 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">电池：</a-col>
							<a-col flex="380px">
								<a-radio-group v-model="config.batterytype" button-style="solid">
									<a-radio-button value="fake"> 隐私 </a-radio-button>
									<a-radio-button value="real"> 真实 </a-radio-button>
								</a-radio-group>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">SSL指纹设置：</a-col>
							<a-col flex="auto">
								<a-row>
									<a-col>
										<a-radio-group v-model="config.is_setssl" button-style="solid">
											<a-radio-button :value="true"> 开启 </a-radio-button>
											<a-radio-button :value="false"> 关闭 </a-radio-button>
										</a-radio-group>
									</a-col>
									<a-col v-if="config.is_setssl">
										<div class="ssl_option">
											<p>勾选为使用，取消勾选为屏蔽。注意：勾选过少可能会导致网页打开异常</p>
											<div class="list">
												<a-checkbox-group v-model="config.ssl_cipherlist" :options="ssl_options" />
											</div>
										</div>
									</a-col>
								</a-row>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">自定义插件指纹：</a-col>
							<a-col flex="0 0 auto">
								<a-radio-group v-model="config.is_useplugin" button-style="solid">
									<a-radio-button :value="true"> 开启 </a-radio-button>
									<a-radio-button :value="false"> 关闭 </a-radio-button>
								</a-radio-group>
							</a-col>
							<a-col flex="0 0 auto" v-if="config.is_useplugin">
								<p class="tip">开启后，自定义的插件可能会影响真实插件功能</p>
							</a-col>
						</a-row>
						<a-row type="flex" v-if="config.is_useplugin">
							<a-col flex="130px" class="label">插件信息：</a-col>
							<a-col flex="auto">
								<a-button style="margin-left: 0" @click="customPluginsDialogVisible = true"
									>自定义插件信息({{ config.pluginInfo.length }}个)</a-button
								>
							</a-col>
						</a-row>
						<a-row type="flex">
							<a-col flex="130px" class="label">附加启动参数：</a-col>
							<a-col flex="380px">
								<a-textarea
									v-model="config.custom_params"
									@change="custom_params_change"
									:auto-size="{ minRows: 1, maxRows: 5 }"
									placeholder="--disable-gpu,--start-maximized"
									style="resize: none" />
							</a-col>
						</a-row>
					</div>
				</div>
				<!--  -->
				<div class="footer">
					<a-button type="primary" size="large" @click="submit" style="width: 100px">完成</a-button>
				</div>
			</div>
			<div class="loading" v-if="loading">
				<a-spin size="large" :spinning="true" />
			</div>
			<!-- 组件 -->
			<template>
				<!-- 编辑平台弹窗 -->
				<EditPlatform @finish="selectPlatform" @cancel="EditPlatform_visible = false" v-if="EditPlatform_visible" />
				<!-- 打开指定网站  -->
				<EditUrl :visible.sync="customUrlDialogVisible" :urls="config.custom_url" @ok="editCustomUrl" />
				<!-- 编辑指纹字体 -->
				<SelectFont
					:visible.sync="selectFontDialogVisible"
					:allFonts="allFonts"
					:lockFonts="lockFonts"
					:selectedFontList="config.fontlist"
					@ok="userSelectFonts" />
				<!-- 自定义插件 -->
				<CustomPlugins :visible.sync="customPluginsDialogVisible" :pluginInfo="config.pluginInfo" @ok="customPluginsChange" />
			</template>
		</main>
		<EnviromentPreview :environment="environment" :config="config" />
	</div>
</template>

<script>
// API
import {
	environment_tag_list,
	client_v1_device,
	deviceSystemConfigApi,
	getUserAgentApi,
	environment_create,
	environment_info,
	environment_update,
} from "@/api/environment.js";
import { user_member_list } from "@/api/member.js";
// 组件
import EnviromentPreview from "./EnviromentPreview.vue";
import EditPlatform from "@/views/manage/environment/components/EditPlatform";
import EditUrl from "@/views/manage/environment/components/EditUrl";
import Draggable from "vuedraggable";
import SelectFont from "./SelectFont.vue";
import CustomPlugins from "./CustomPlugins.vue";
// 其他
import timezoneList from "@/utils/custome_timezone.json";
import { languageList } from "@/utils/languageList";
import { resolutionList, anglelist } from "@/utils/environmentConfigList.js";
// 默认配置
// ❌ 已废弃
// ❗ 需要特殊处理
const environment = {
	env_name: "",
	platform_id: "",
	country_id: "",
	platform_country_id: "",
	shop_account: "",
	shop_password: "",
	tagIds: [], // ❗ 标签id字符串
	device_id: "",
	business_short: "",
	member: [], //❗ 成员id字符串
	device_name: "",
};
const config = {
	// 账号信息
	beizhu: "",
	// 环境参数
	platform: "Windows",
	os_version: "",
	chrome_version: "",
	user_agent: "", // ❗❗❗
	ua_json: {}, // ❗❗❗
	cookie: "",
	custom_url: [],
	// 常用设置 ----------
	is_blockpic: false,
	is_disablevideo: false,
	is_blocksound: false,
	nofity_status: false, // ❗
	is_recordtaburl: false,
	is_sync_bookmarks: false,
	is_sync_indexedDB: false,
	is_sync_localstorage: false,
	is_deltemp_blocksync: false,
	is_delcookie_blocksync: false,
	is_disable_gpu: false,
	// 指纹设置
	is_custom_timezone: false,
	timezone: "",
	webrtc_set: 0,
	location: {
		location_status: 0,
		is_baseon_location: true,
		longitude: 0,
		latitude: 0,
		precision: 1000,
	},
	language: true,
	lang: "",
	langSortList: ["zh-CN", "en"], // ❗提交时需要转为语言对象
	ac_lang: "",
	isresolution: "2",
	resolution: "1920x1080",
	isuse_font: true,
	fontlist: [], // ❗
	canvas: true,
	webgl_graphic: 1,
	webgl_des: {
		// ❗
		status: true,
		webgl_oem: "",
		webgl_device: "",
	},
	audio_context: true,
	media_device: true,
	client_rects: true,
	speech_voices: true,
	hardware_concurrency: 4,
	memory_size: 8,
	ispc_name: false,
	pc_name: "DESKTOP-ZJCBVR",
	mac_address: "", // ❗
	do_not_track: 0,
	port_protect: {
		status: false,
		list: "",
	},
	is_enablebluetooth: false,
	batterytype: "fake",
	key2fa: "",
	is_setssl: false,
	ssl_cipherlist: [
		"TLS_RSA_WITH_NULL_SHA",
		"TLS_RSA_WITH_3DES_EDE_CBC_SHA",
		"TLS_RSA_WITH_AES_128_CBC_SHA",
		"TLS_RSA_WITH_AES_256_CBC_SHA",
		"TLS_PSK_WITH_AES_128_CBC_SHA",
		"TLS_PSK_WITH_AES_256_CBC_SHA",
		"TLS_RSA_WITH_AES_128_GCM_SHA256",
		"TLS_RSA_WITH_AES_256_GCM_SHA384",
		"TLS_AES_128_GCM_SHA256",
		"TLS_AES_256_GCM_SHA384",
		"TLS_CHACHA20_POLY1305_SHA256",
		"TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA",
		"TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA",
		"TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA",
		"TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA",
		"TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256",
		"TLS_ECDHE_ECDSA_WITH_AES_256_GCM_SHA384",
		"TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256",
		"TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384",
		"TLS_ECDHE_PSK_WITH_AES_128_CBC_SHA",
		"TLS_ECDHE_PSK_WITH_AES_256_CBC_SHA",
		"TLS_ECDHE_RSA_WITH_CHACHA20_POLY1305_SHA256",
		"TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305_SHA256",
		"TLS_ECDHE_PSK_WITH_CHACHA20_POLY1305_SHA256",
	],
	is_useplugin: false,
	pluginInfo: [],
	custom_params: "",
	device_type: 1,
	// 固定参数
	is_sync_cookies: true, // 预留字段
	kernel_version: "102",
	// 已废弃或未知参数 // 2024-08-19 已确认下面字段已废弃
	// linux: "", // ❌
	// android: "", // ❌
	// userAgentType: ["window"], // ❌
	// browser: "0",
};
const ssl_options = [
	"TLS_RSA_WITH_NULL_SHA",
	"TLS_RSA_WITH_3DES_EDE_CBC_SHA",
	"TLS_RSA_WITH_AES_128_CBC_SHA",
	"TLS_RSA_WITH_AES_256_CBC_SHA",
	"TLS_PSK_WITH_AES_128_CBC_SHA",
	"TLS_PSK_WITH_AES_256_CBC_SHA",
	"TLS_RSA_WITH_AES_128_GCM_SHA256",
	"TLS_RSA_WITH_AES_256_GCM_SHA384",
	"TLS_AES_128_GCM_SHA256",
	"TLS_AES_256_GCM_SHA384",
	"TLS_CHACHA20_POLY1305_SHA256",
	"TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA",
	"TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA",
	"TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA",
	"TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA",
	"TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256",
	"TLS_ECDHE_ECDSA_WITH_AES_256_GCM_SHA384",
	"TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256",
	"TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384",
	"TLS_ECDHE_PSK_WITH_AES_128_CBC_SHA",
	"TLS_ECDHE_PSK_WITH_AES_256_CBC_SHA",
	"TLS_ECDHE_RSA_WITH_CHACHA20_POLY1305_SHA256",
	"TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305_SHA256",
	"TLS_ECDHE_PSK_WITH_CHACHA20_POLY1305_SHA256",
];
export default {
	components: {},
	name: "AddEnviroment",
	components: { EnviromentPreview, EditPlatform, EditUrl, Draggable, SelectFont, CustomPlugins },
	data() {
		return {
			environment: JSON.parse(JSON.stringify(environment)),
			config: JSON.parse(JSON.stringify(config)),
			platform_title: "", // 所属平台显示文字
			deviceSystemConfig: {}, // 系统平台、系统版本、浏览器版本配置
			tagList: [], // 环境标签列表
			memberList: [], // 成员列表
			deviceList: [], // 设备列表
			EditPlatform_visible: false,
			loading: false,
			custom_url_input: "",
			customUrlDialogVisible: false,

			advancedSettingVisible: false,
			timezoneList,
			addLanguageVisible: false,
			languageList,
			selectedLanguage: [],
			resolution_x: 1920,
			resolution_y: 1080,
			resolutionList,
			selectFontDialogVisible: false,
			lockFonts: [],
			allFonts: [],
			hardware_concurrency_options: [2, 3, 4, 6, 8, 16],
			memory_size_options: [2, 4, 8, 16, 32],
			ssl_options,
			info: {},
			lastLanguage: "",
			customPluginsDialogVisible: false,
		};
	},
	computed: {
		os_version_options() {
			if (!this.deviceSystemConfig.platform) return [];
			return this.deviceSystemConfig.platform.find(item => item.platform === this.config.platform).os_version.map(item => String(item));
		},
		chrome_version_options() {
			if (!this.deviceSystemConfig.chrome_version) return [];
			if (this.config.platform != "Windows") return this.deviceSystemConfig.chrome_version.map(item => String(item));
			switch (+this.config.os_version) {
				case 7:
				case 8:
					return this.deviceSystemConfig.chrome_version.filter(item => item < 110).map(item => String(item));
				case 10:
				case 11:
					return this.deviceSystemConfig.chrome_version.map(item => String(item));
				default:
					return [];
			}
		},
	},
	created() {
		this.getInitData();
		this.getFontList();
		if (this.$route.query.id) {
			this.getEnvInfo();
		} else {
			this.getUserAgent();
			this.getMacAddress();
			this.getWebglDes();
			this.getDeviceName();
		}
	},
	watch: {
		// 新建环境时，初始化字体->获取全部字体，然后直接生成随机字体；
		// 编辑环境时，只需要初始化字体，当主动点击刷一刷时再生成随机字体；
		"$store.state.localFontlist": {
			handler(newVal) {
				this.initFont();
				if (!this.$route.query.id) {
					this.getRandomFonts();
				}
			},
			deep: true,
		},
	},
	methods: {
		customPluginsChange(e) {
			this.config.pluginInfo = e;
		},
		// 编辑 - 获取环境信息
		async getEnvInfo() {
			this.loading = true;
			const info = await environment_info({ id: this.$route.query.id })
				.then(res => {
					if (res.data.code == 200) {
						return res.data.data;
					} else {
						this.$message.error(res.data.msg);
						return null;
					}
				})
				.finally(() => {
					this.loading = false;
				});
			if (!info) return;
			this.info = info;

			// 处理数据
			// environment
			for (let key in this.environment) {
				this.environment[key] = info[key];
			}
			this.environment.tagIds = info.tag.map(item => item.id);
			this.environment.member = info.member.map(item => item.id);
			this.platform_title = info.platform_title;
			this.environment.device_id = info.device_id || "";
			// config
			Object.assign(this.config, info.config);
			// this.device_type = info.config.device_type;
			this.config.fontlist = info.config.fontlist.split(",");
			this.config.langSortList = info.config.langSortList.map(item => item.value);
			this.config.nofity_status = info.config.nofity_status === "disable" ? true : false;
			// 处理数据类型错误问题
			this.config.os_version = String(info.config.os_version);
			this.config.chrome_version = String(info.config.chrome_version);
		},
		// 编辑&新建 - 提交
		async submit() {
			if (this.loading) return;

			const environment = JSON.parse(JSON.stringify(this.environment));
			const config = JSON.parse(JSON.stringify(this.config));
			// 环境名称
			if (!environment.env_name) return this.$message.error("请输入环境名称");
			// 所属平台
			if (!environment.platform_id) return this.$message.error("请选择店铺所属平台");
			// cookie
			if (!this.validateCookie(false)) return;

			this.loading = true;
			// 处理数据------------
			// 环境标签
			environment.tagIds = this.environment.tagIds.join(",");
			// 授权成员
			environment.member = this.environment.member.join(",");
			// 禁止网页通知
			config.nofity_status = this.config.nofity_status ? "disable" : "ask";
			// 语言
			config.lang = this.config.langSortList[0];
			config.langSortList = this.config.langSortList.map(item => {
				return languageList.find(l => l.value === item);
			});
			config.ac_lang = this.config.langSortList.reduce((res, item, index) => {
				return (res += `,${item};q=${1 - index * 0.1}`);
			});
			// 字体
			config.fontlist = this.config.fontlist.join(",");

			if (this.$route.query.id) {
				// 编辑
				const { data } = await environment_update({
					id: this.$route.query.id,
					environment,
					config,
				}).finally(() => {
					this.loading = false;
				});
				if (data.code == 200) {
					this.$message.success("修改成功");
					this.$router.push("/manage/environment");
				}
			} else {
				// 新建
				const { data } = await environment_create({
					environment,
					config,
				}).finally(() => {
					this.loading = false;
				});
				if (data.code == 200) {
					this.$message.success("环境创建成功");
					this.$router.push("/manage/environment");
				}
			}
		},
		// 高级设置显示/隐藏
		openAdvancedSetting() {
			this.advancedSettingVisible = !this.advancedSettingVisible;

			if (this.advancedSettingVisible) {
				setTimeout(() => {
					this.$refs.advancedSetting.scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
		},
		// 选择所属平台
		selectPlatform(obj) {
			this.EditPlatform_visible = false;
			//
			this.environment.platform_id = obj.platform_id;
			this.environment.country_id = obj.country_id;
			this.environment.platform_country_id = obj.id;
			//
			this.platform_title = `${obj.platform_type}/${obj.platform_site}/${obj.site}`;
		},
		// 系统平台
		changePlatform(p) {
			if (p === this.config.platform) return;
			this.config.platform = p;
			this.config.os_version = "";
			this.config.chrome_version = "";
			this.getUserAgent();
		},
		// 系统版本
		osVersionChange() {
			this.config.chrome_version = "";
			this.getUserAgent();
		},
		// 打开指定网站 -> 添加URL
		addUrl() {
			if (this.config.custom_url.length >= 10) {
				this.$message.error("已达到添加上限");
				return;
			}
			if (this.config.custom_url.includes(this.custom_url_input)) {
				this.$message.error("URL已存在");
				return;
			}

			// 不可为空
			if (!this.custom_url_input) {
				this.$message.error("请输入URL");
				return false;
			}
			// 开头必须存在https:// 或者http://
			if (!this.custom_url_input.startsWith("https://") && !this.custom_url_input.startsWith("http://")) {
				this.$message.error("请输入以http或者https开头的url");
				return false;
			}
			// 存在https://或者http://时   后面字符必须大于等于1
			if (this.custom_url_input.startsWith("https://")) {
				if (this.custom_url_input.length - 8 <= 0) return this.$message.error("请输入以http或者https开头的url");
			}
			if (this.custom_url_input.startsWith("http://")) {
				if (this.custom_url_input.length - 7 <= 0) return this.$message.error("请输入以http或者https开头的url");
			}

			this.config.custom_url.push(this.custom_url_input);
			this.custom_url_input = "";
		},
		// 打开指定网站 - 编辑 -> 确定
		editCustomUrl(url) {
			this.config.custom_url = JSON.parse(JSON.stringify(url));
		},
		// 清空 - 打开指定网站URL
		clearCustomUrl() {
			this.config.custom_url = [];
		},
		openAddLanguageDialog() {
			this.selectedLanguage = JSON.parse(JSON.stringify(this.config.langSortList));
			this.addLanguageVisible = true;
		},
		// 选择语言
		languageChange(lang) {
			// 当只选择一个时记录下当前选择的语言，当用户勾选数为 0 时，强制选择该语言
			if (lang.length == 1) {
				this.lastLanguage = lang[0];
			}
			if (lang.length > 10) {
				this.$message.error("最多添加10种语言");
				this.selectedLanguage = lang.slice(0, 10);
			}
			if (lang.length == 0) {
				this.$message.error("至少选择一种语言");
				this.selectedLanguage = [this.lastLanguage];
			}
		},
		// 确认语言
		addLanguageConfirm() {
			this.addLanguageVisible = false;
			this.config.langSortList = this.selectedLanguage;
		},
		// 输入分辨率
		resolutionInputChange() {
			this.config.resolution = `${this.resolution_x}x${this.resolution_y}`;
		},
		// 选择分辨率
		resolutionChange(r) {
			this.config.resolution = r;
			const [resolution_x, resolution_y] = r.split("x");
			this.resolution_x = resolution_x;
			this.resolution_y = resolution_y;
		},
		// 初始化字体 - 获取全部字体和锁定字体
		initFont() {
			const platformMap = {
				Windows: "windows",
				macOS: "macos",
				Android: "android",
				IOS: "ios",
				Linux: "linux",
			};
			const key = this.config.platform || "windows";
			const allFonts = this.$store.state.localFontlist.font_datas[platformMap[key]].randomfont;
			this.allFonts = allFonts;
			const lockFonts = this.$store.state.localFontlist.font_datas[platformMap[key]].lockfont;
			this.lockFonts = lockFonts;
		},
		// 生成随机字体
		getRandomFonts() {
			const allFontlist = this.allFonts.toSorted((a, b) => Math.random() - 0.5);
			const all_length = allFontlist.length;
			const randomRange = [1, all_length];
			const random_length = Math.floor(Math.random() * (randomRange[1] - randomRange[0] + 1)) + randomRange[0];
			this.config.fontlist = [...this.lockFonts, ...allFontlist.slice(0, random_length)];
		},
		// 编辑指纹字体列表 - 确定
		userSelectFonts(list) {
			this.config.fontlist = list;
		},
		// 发送websocket获取指纹字体
		getFontList() {
			// 获取指纹字体列表
			let c_1 = JSON.stringify({
				message: "/connection/fontlist",
			});
			let b_1 = this.$encrypt(c_1);
			this.$store.state.websocket.send(b_1);
		},
		// 生成webgl元数据
		getWebglDes() {
			const config = anglelist[Math.floor(Math.random() * 43)].split("|");
			const [webgl_oem, webgl_device] = config;
			this.config.webgl_des.webgl_oem = webgl_oem;
			this.config.webgl_des.webgl_device = webgl_device;
		},
		// 生成设备名称
		getDeviceName() {
			const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
			let result = "";
			for (let i = 0; i < 6; i++) {
				const randomIndex = Math.floor(Math.random() * charset.length);
				result += charset[randomIndex];
			}
			this.config.pc_name = `DESKTOP-${result}`;
		},
		// 生成MAC地址
		getMacAddress(e) {
			const arr = [
				(0x52).toString(16),
				(0x54).toString(16),
				(0x00).toString(16),
				Math.floor(Math.random() * 0xff).toString(16),
				Math.floor(Math.random() * 0xff).toString(16),
				Math.floor(Math.random() * 0xff).toString(16),
			];
			if (e && e.target) {
				if (e.target.value) {
					this.config.mac_address = arr.join(":");
				} else {
					this.config.mac_address = "";
				}
			} else {
				this.config.mac_address = arr.join(":");
			}
		},
		// 输入端口
		port_protect_list_change(e) {
			const input = e.target.value;

			this.config.port_protect.list = input.replace(/[^\d,]/g, "").replace(/,+/g, ",");
		},
		// 输入启动参数
		custom_params_change(e) {
			const str = e.target.value;
			// 删除空格、中文'，'替换为','、限制输入2000个字符
			this.custom_params = str.replace(/\s/g, "").replace("，", ",").slice(0, 2000);
		},
		// 指纹设置 - 是否基于IP生成时区
		customTimezoneChange(e) {
			this.config.is_custom_timezone = !e;
		},
		// 获取初始化数据
		async getInitData() {
			const promiseArr = [];
			// 获取环境标签
			promiseArr.push(environment_tag_list().then(res => res.data.data.list));
			// 获取成员列表
			promiseArr.push(user_member_list().then(res => res.data.data));
			// 获取系统平台，系统版本，浏览器版本
			promiseArr.push(
				deviceSystemConfigApi().then(res => {
					const config = res.data.data;
					config.platform = config.platform.reverse();
					return config;
				})
			);
			// 获取设备列表
			promiseArr.push(client_v1_device().then(res => res.data.data.list));
			//
			const res = await Promise.all(promiseArr);

			this.tagList = res[0];
			this.memberList = res[1];
			this.deviceSystemConfig = res[2];
			this.deviceList = res[3];
		},
		// 获取User Agent
		getUserAgent() {
			this.loading = true;
			const params = {
				ua_platform: this.config.platform || undefined,
				os_version: this.config.os_version || undefined,
				chrome_version: this.config.chrome_version || undefined,
			};
			getUserAgentApi(params)
				.then(res => {
					const ua_json = res.data.data;
					this.config.user_agent = ua_json.http_useragent;
					this.config.ua_json = ua_json;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		// 验证用户输入的cookie是否合法
		// cookie必须包含 name,value,domain 字段，其余字段可选
		validateCookie(tip) {
			const cookie = this.config.cookie;
			// 可以为空
			if (!cookie) return true;
			// 判断是否符合JSON格式
			try {
				const res = JSON.parse(cookie);
				// 必须是 []
				if (!Array.isArray(res)) throw Error("Cookie格式错误");

				// 每一项必须是 {}
				if (!res.length || !res.every(item => Object.prototype.toString.call(item) === "[object Object]")) throw Error("Cookie格式错误");

				// 每一项都必须包含 name,value,domain 字段
				res.forEach(item => {
					if (!item.hasOwnProperty("name") || !item.hasOwnProperty("value") || !item.hasOwnProperty("domain"))
						throw Error("Cookie格式错误，每条Cookie必须包含 name、value、domain 字段");
				});
				return true;
			} catch (error) {
				if (tip) {
					this.$message.error(error.message);
				}
				return false;
			}
		},
		changeDeviceType() {
			this.environment.device_id = "";
		},
	},
};
</script>
<style lang="less" scoped>
.AddEnviroment {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	& > main {
		width: 1200px;
		padding: 20px 20px 0;
		background-color: #fff;
		margin-bottom: 20px;
		& > nav {
			display: flex;
			align-items: center;
			h1 {
				color: #28282a;
				font-size: 24px;
				font-weight: bold;
				line-height: 24px;
				margin-left: 20px;
			}
		}
		& > .loading {
			position: fixed;
			inset: 0;
			background-color: rgba(255, 255, 255, 0.4);
			z-index: 1000;
			display: flex;
			align-items: center;
			justify-content: center;
			backdrop-filter: blur(1px);
		}
		.form {
			padding: 0 100px;
			h2 {
				font-size: 18px;
				line-height: 20px;
				color: #28282a;
				font-weight: bold;
				position: relative;
				margin: 40px 0 30px;
				padding-left: 10px;
				&::before {
					content: "";
					position: absolute;
					width: 4px;
					height: 100%;
					background-color: #3569fd;
					left: 0;
				}
			}
			h3 {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 40px 0 30px;
				cursor: pointer;
				background-color: #edf2ff;
				padding: 6px 0;
				span {
					color: #4c84ff;
					font-size: 16px;
					font-weight: bold;
				}
				img {
					margin-left: 2px;
					transition: all 0.3s;
				}
				&.active {
					img {
						transform: rotate(-180deg);
					}
				}
			}
			::v-deep .ant-row-flex {
				margin-top: 20px;
				.ant-row-flex {
					margin-top: 0;
				}
				.ant-col {
					&.label {
						text-align: right;
						position: relative;
						line-height: 32px;
						white-space: nowrap;
					}
					&.code_2fa {
						left: -26px;
					}
					p.tip {
						line-height: 32px;
						margin-left: 10px;
					}
					&.required {
						&::before {
							display: inline-block;
							margin-right: 2px;
							color: #f5222d;
							font-size: 14px;
							font-family: SimSun, sans-serif;
							content: "*";
							font-weight: 600;
						}
					}
					&.ant-col-push-1 {
						left: 6px;
					}
					.tags {
						display: flex;
						align-items: center;
						user-select: none;
						.item {
							display: flex;
							align-items: center;
							padding: 0 20px;
							border: 1px solid #d9d9d9;
							border-radius: 4px;
							cursor: pointer;
							height: 32px;
							& + .item {
								margin-left: 10px;
							}
							&.active {
								border-color: #4c84ff;
								background-color: rgba(76, 132, 255, 0.1);
								color: #4c84ff;
								img {
									filter: unset;
									opacity: unset;
								}
							}
							img {
								display: block;
								margin-right: 8px;
								filter: grayscale(1);
								opacity: 0.5;
							}
						}
					}

					.ant-select {
						width: 100%;
					}
					textarea.ant-input {
						resize: none;
						word-break: break-all;
					}
					.ant-btn {
						margin-left: 10px;
					}
					.urls {
						text-align: left;
						position: relative;
						margin-top: 10px;
						z-index: 1;
						span {
							position: absolute;
							left: calc(100% + 10px);
							top: 0;
							bottom: 0;
							cursor: pointer;
							color: #4c84ff;
							white-space: nowrap;
						}
					}
					.ant-radio-group {
						height: 32px;
						display: flex;
						align-items: center;
					}
				}
			}
			.advancedSetting {
				::v-deep .ant-row-flex {
					.ant-col {
						& > label {
							display: block;
							height: 32px;
							display: flex;
							align-items: center;
							// padding-left: 10px;
							width: fit-content;
							cursor: pointer;
							span {
								margin-left: 10px;
							}
						}
						.ant-select:not(.selected) {
							.ant-select-selection__placeholder {
								display: block !important;
							}
						}
						.langSortList {
							user-select: none;
							margin-top: 10px;

							.language-item {
								height: 32px;
								padding: 0 12px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								border-bottom: 1px solid #d9d9d9;
								cursor: move;
								&.sortable-chosen {
									background: #edf2ff;
									opacity: 1 !important;
									&.ghost {
										opacity: 0.5 !important;
									}
								}
							}
						}
						.resolution {
							display: flex;
							align-items: center;
							margin-top: 10px;
						}
						.font_list {
							margin-top: 10px;
							.selectedFontList {
								overflow: hidden;
								text-overflow: ellipsis;
								overflow: hidden;
								line-clamp: 2;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								word-break: break-all;
								position: relative;
								.font {
									height: 48px;
									line-height: 24px;
									width: calc(100% - 30px);
								}
								.count {
									position: absolute;
									height: 24px;
									line-height: 24px;
									right: 0;
									bottom: 0;
								}
							}
							.option {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-top: 6px;
								div {
									color: #598dff;
									cursor: pointer;

									&.reload {
										padding-left: 18px;
										background: url(../../../../assets/img/xinjian/shua.png) no-repeat center left;
									}
								}
							}
						}
						.webgl_des {
							width: 600px;
							padding: 10px;
							margin-top: 10px;
							border-radius: 4px;
							background-color: #f9faff;
						}
						.ssl_option {
							margin-top: 10px;
							width: 800px;
							.list {
								background: #f1f3ff;
								border-radius: 4px;
								margin-top: 10px;
								padding: 10px;
								.ant-checkbox-group {
									label {
										display: block;
										& + label {
											margin-top: 6px;
										}
									}
								}
							}
						}
					}
				}
			}
			.fingerprintSettings {
				::v-deep .ant-row-flex {
					margin-top: 30px;
					.ant-row,
					.ant-row-flex {
						margin-top: 0;
					}
				}
			}
			.footer {
				position: sticky;
				bottom: 0;
				height: 100px;
				display: flex;
				align-items: center;
				background-color: #fff;
				gap: 0 20px;
				z-index: 100;
			}
		}
	}
}
</style>
<style>
.selectVariableDropDown {
	max-height: 200px;
	overflow-y: auto;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
</style>
